import api from "../../../services/api.service"


export const messageTemplateModule = {
    namespaced: true,
    state: {
        data: {types: [], categories: []},
    },
    getters: {
        fileTags(state, getters, rootState, rootGetters) {
            return [
                {value: "Kaufvertrag", title: "Kaufvertrag", icon: "fas fa-file-contract"},
                {value: "Finanzierung", title: "Finanzierung", icon: "fas fa-file-contract"},
                {value: "Bauakte", title: "Bauakte", icon: "fas fa-file-contract"},
                {value: "Hausverwaltung", title: "Hausverwaltung", icon: "fas fa-file-contract"},
                {value: "Versicherung", title: "Versicherung", icon: "fas fa-file-contract"},
                {value: "Fotos", title: "Fotos", icon: "fas fa-file-contract"},
                {value: "Marketing", title: "Marketing", icon: "fas fa-file-contract"},
                {value: "Gutachten ", title: "Gutachten ", icon: "fas fa-file-contract"},
                {value: "Kreditvertrag", title: "Kreditvertrag", icon: "fas fa-file-contract"},
                {value: "Bauvorhaben", title: "Bauvorhaben", icon: "fas fa-file-contract"},
                {value: "Expose", title: "Expose", icon: "fas fa-file-contract"},
                {value: "Sonstiges", title: "Sonstiges", icon: "fas fa-file-contract"},
            ]
        },
        allMessageTemplateUsers(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('messageTemplate_all_user');
        },
        canOperateMessageTemplate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canOperate']('messageTemplate');
        },
        canReadRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('real_estate');
        },
        canEditRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('real_estate');
        },
        canUploadExpose(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('messageTemplate_expose');
        },
        canCreateInvoice(state, getters, rootState, rootGetters) {
            return rootGetters['user/canCreate']('invoice');
        },
        canEditInvoice(state, getters, rootState, rootGetters) {
            return rootGetters['user/canCreate']('invoice');
        },
        getAllCategories(state) {
            if (state.data == null || state.data.categories == null)
                return [];
            return state.data.categories;
        },

        getAllTypes(state) {
            if (state.data == null || state.data.types == null)
                return [];
            return state.data.types;
        },
        allStatusNoActive() {
            return [
                {value: "NEW", text: 'NEW'},
                {value: 'EXPOSE', text: 'EXPOSE'},
                {value: 'DISABLED', text: 'DISABLED'}
            ]
        },
        allStatuse() {
            return [
                {value: "Neubau", text: 'Neubau'},
                {value: 'Altbau', text: 'Altbau'},
                {value: 'Villahaus', text: 'Villahaus'},
                {value: 'Altbau_Renoviert', text: 'Altbau-Renoviert'},
                {value: 'Tiny_Houses', text: 'Tiny-Houses'},

            ]
        },
        allEventsList() {
            return [
                {value: "PRE_CHECKIN", text: 'Pre Checkin'},
                {value: 'POST_CHECKIN', text: 'Post Checkin'},
                {value: 'PRE_CHECKOUT', text: 'Pre Checkout'},
                {value: 'POST_CHECKOUT', text: 'Post Checkout'},
                {value: 'POST_BOOKING', text: 'Post Booking'},
                {value: 'CUSTOM_TIME', text: 'Custom Time'},

            ]
        },
        allChannelsList() {
            return [
                {value: "SMS", text: 'SMS'},
                {value: 'EMAIL', text: 'EMAIL'},
                {value: 'HOSTAWAY', text: 'HOSTAWAY'},

            ]
        },


    },
    mutations: {
        updateData(state, data) {
            let stateData = {};
            stateData.loaded = true;
            stateData.categories = data["Categories"];
            stateData.types = data["Types"];
            state.data = stateData;
        }
    },
    actions: {

        copySuccess(actions, id) {
            actions.dispatch('notifications/success',
                {title: 'Success', message: 'MessageTemplate  ID ' + id + ' copied'},
                {root: true});

        },


        getAllMessageTemplates(actions, {page, amount, filter, sortcolumn, sortby}) {

            return new Promise(function (resolve, reject) {
                api.sendRequest('messageTemplate/get', {
                    buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),
                    page,
                    amount,
                    sortcolumn,
                    sortby
                }, filter).then(
                    (ans) => {
                        if (ans["MessageTemplates"] == null)
                            return reject('Could not get MessageTemplates ');

                        resolve(ans);
                    }, (error) => {
                        reject(error.message)
                    })
            })

        },


        sendMessageTemplate(actions,{contractId, messageTemplateId}) {
            return new Promise((resolve, reject) => {

                api.sendRequest('messageTemplate/sendMessageTemplate/' + messageTemplateId, {contractId}).then(
                    (ans) => {

                        if (ans["MessageTemplate"] == null)
                            return reject('Could not get messageTemplate ');

                        resolve(ans["MessageTemplate"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        getMessageTemplateById(actions, id) {
            return new Promise((resolve, reject) => {

                api.sendRequest('messageTemplate/id/' + id, {}).then(
                    (ans) => {

                        if (ans["MessageTemplate"] == null)
                            return reject('Could not get messageTemplate ');

                        resolve(ans["MessageTemplate"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        updateMessageTemplate(actions, messageTemplate) {
            return new Promise((resolve, reject) => {

                api.sendRequest('messageTemplate/update', null, messageTemplate).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update messageTemplate', message: 'MessageTemplate updated successfully'},
                            {root: true});

                        if (ans["MessageTemplate"] == null)
                            return reject('Could not update messageTemplate ');

                        resolve(ans["MessageTemplate"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        addMessageTemplate(actions, messageTemplate) {
            return new Promise((resolve, reject) => {
                api.sendRequest('messageTemplate/add', null, messageTemplate).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Add messageTemplate', message: 'MessageTemplate created successfully'},
                            {root: true});

                        if (ans["MessageTemplateId"] == null)
                            return reject('Could not create messageTemplate ');

                        console.log("messageTemplate answer created: ", ans)
                        resolve(ans["MessageTemplateId"]);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Add messageTemplate',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        getExcelTemplateUrl(actions) {
            return new Promise(function (resolve, reject) {
                let params = {
                    bearer: encodeURI(localStorage.Bearer)
                };
                let query = new URLSearchParams(params).toString()
                resolve(window.config.apiUrl + 'excel/download/template/messageTemplate?' + query);
            });
        },
        getExcelTemplateUrlClients(actions) {
            return new Promise(function (resolve, reject) {
                let params = {
                    bearer: encodeURI(localStorage.Bearer)
                };
                let query = new URLSearchParams(params).toString()
                resolve(window.config.apiUrl + 'excel/download/template/messageTemplateClient?' + query);
            });
        },
        sendUserInviteMail(actions, {userIds, messageTemplateId}) {

            return new Promise(function (resolve, reject) {
                api.sendRequest('messageTemplate/sendUserMail',
                    {userIds, messageTemplateId}, null).then(
                    (ans) => {
                        if (ans["userIds"] == null)
                            return reject('Could not send email ');

                        actions.dispatch('notifications/success',
                            {title: 'Email new User', message: 'Email sent successfully'},
                            {root: true});

                        resolve(ans["userIds"]);
                    }, (error) => {

                        actions.dispatch('notifications/error',
                            {
                                title: 'Email new User',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })

        },
        sendBankAgentMail(actions, {userIds, messageTemplateId}) {

            return new Promise(function (resolve, reject) {
                api.sendRequest('messageTemplate/sendAgentMail',
                    {userIds, messageTemplateId}, null).then(
                    (ans) => {
                        if (ans["userIds"] == null)
                            return reject('Could not send email ');

                        actions.dispatch('notifications/success',
                            {title: 'Email agents', message: 'Email sent successfully'},
                            {root: true});

                        resolve(ans["userIds"]);
                    }, (error) => {

                        actions.dispatch('notifications/error',
                            {
                                title: 'Email agents',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })

        },

        addExpenses(actions, {id, expenses}) {
            return new Promise((resolve, reject) => {
                api.sendRequest('messageTemplate/addExpenses/' + id, {}, expenses).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update MessageTemplate', message: 'MessageTemplate expenses added successfully'},
                            {root: true});
                        resolve(ans["MessageTemplate"]);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Adding expense',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        calculatePreviousMonth(actions, id) {
            return new Promise((resolve, reject) => {
                api.sendRequest('messageTemplate/expenses-calculation/previous/' + id).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update MessageTemplate', message: 'MessageTemplate expenses added successfully'},
                            {root: true});
                        resolve(ans["messageTemplateExpenses"]);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Adding expense',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        deleteExpenses(actions, {id, expenseId}) {
            return new Promise((resolve, reject) => {
                api.sendRequest('messageTemplate/deleteExpenses/' + id + '/' + expenseId, {}, null).then(
                    (ans) => {

                        if (ans["MessageTemplate"] == null)
                            return reject('Could not delete messageTemplate expenses');
                        actions.dispatch('notifications/success',
                            {title: 'Update MessageTemplate', message: 'MessageTemplate expenses deleted successfully'},
                            {root: true});
                        resolve(ans["MessageTemplate"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        retrieveInvoice(actions, id) {
            return new Promise((resolve, reject) => {
                api.sendRequest('messageTemplate/invoice/retrieve/' + id, {}).then(
                    (ans) => {
                        resolve({invoice: ans["Invoice"], invoiceDetails: ans["InvoiceDetails"]});
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        createDraftInvoice(actions, invoice) {
            return new Promise((resolve, reject) => {
                api.sendRequest('messageTemplate/invoice/draft/generate', {}, invoice).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update MessageTemplate', message: 'MessageTemplate invoice created successfully'},
                            {root: true});
                        resolve({invoice: ans["Invoice"], invoiceDetails: ans["InvoiceDetails"]});
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Create invoice',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        updateDraftInvoice(actions, {id, invoice}) {
            return new Promise((resolve, reject) => {
                api.sendRequest('messageTemplate/invoice/draft/update/' + id, {}, invoice).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update MessageTemplate', message: 'MessageTemplate invoice updated successfully'},
                            {root: true});
                        resolve({invoice: ans["Invoice"], invoiceDetails: ans["InvoiceDetails"]});
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Update invoice',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        finalizeDraftInvoice(actions, invoiceId) {
            return new Promise((resolve, reject) => {
                api.sendRequest('messageTemplate/invoice/draft/finalize/' + invoiceId, {}).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {
                                title: 'Update MessageTemplate',
                                message: 'MessageTemplate invoice finalized successfully'
                            },
                            {root: true});
                        resolve({invoice: ans["Invoice"], invoiceDetails: ans["InvoiceDetails"]});
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Finalize invoice',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        deleteInvoice(actions, id) {
            return new Promise((resolve, reject) => {
                api.sendRequest('messageTemplate/invoice/delete/' + id, {}).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update MessageTemplate', message: 'MessageTemplate invoice deleted successfully'},
                            {root: true});
                        resolve(ans["MessageTemplate"]);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: 'Delete invoice',
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        }
    },
};
