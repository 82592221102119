import api from "../../../services/api.service"


export const pricelabsModule = {
    namespaced: true,
    state: {},
    getters: {
        canReadPricelabs(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('pricelabs');
        },
        canEditPricelabs(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('pricelabs');
        },
    },
    mutations: {},
    actions: {
        getData() {
            return new Promise(function (resolve) {

                resolve();

            })
        },
        calcPrices(actions, {body}) {
            return new Promise((resolve, reject) => {

                api.sendRequest('pricelabs/prices/total', {},body).then(
                    (ans) => {

                        if (ans["Prices"] == null)
                            return reject('Could get prices');
                        actions.dispatch('notifications/success',
                            {title: 'Pricelabs', message: 'Retrieved prices successfully'},
                            {root: true});
                        resolve(ans["Prices"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        pricesForCalendar(actions, {body}) {
            return new Promise((resolve, reject) => {

                api.sendRequest('pricelabs/prices/total', {},body).then(
                    (ans) => {

                        if (ans["Prices"] == null)
                            return reject('Could get prices');
                        actions.dispatch('notifications/success',
                            {title: 'Pricelabs', message: 'Retrieved prices successfully'},
                            {root: true});
                        resolve(ans["Prices"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        getPrices(actions, {filter}) {
            return new Promise((resolve, reject) => {
                api.sendRequest('pricelabs/prices/all', {},filter).then(
                    (ans) => {
                        if (ans["Prices"] == null)
                            return reject('Could get prices');
                        resolve(ans["Prices"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
    },
};
