<template>
  <div id="app-inner" >
    <notification-listener/>
    <router-view/>
  </div>
</template>

<style>
html, body, #app {
  height: 100%;
}
</style>
<script>
import { mapActions, mapState } from 'vuex'
import $ from 'jquery'
import NotificationListener from '@core/components/toastification/NotificationListener'

export default {
  name: "login",
  components: { NotificationListener },
  data() {
    return {
      notificationAnimation: {
        enter(element) {
          let id = parseInt($(element).data('id'));
          let elementData = this.$data.list.find(o => o.id == id);
          console.log("Notification in", elementData);
          if (elementData != null && typeof elementData.data === "object" && typeof elementData.data.onEnter == "function") {
            elementData.data.onEnter(elementData.data)
          }
          return {
            height: [element.clientHeight, 0],
          }
        },
        leave(element) {
          let id = parseInt($(element).data('id'));
          let elementData = this.$data.list.find(o => o.id == id);
          console.log("Notification out", elementData);
          if (elementData != null && typeof elementData.data === "object" && typeof elementData.data.onClose == "function") {
            elementData.data.onClose(elementData.data)
          }
          return {
            height: 0,
            opacity: 0
          }
        }
      }
    }
  },

  computed: {
    // ...mapState('user', ['status'])
    ...mapState({
      user: function (store) {
        return {sidebar: store.user.status, user: store.user.status};
      }
    })
  },
  methods:{

  }

}
</script>
