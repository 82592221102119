import api from '../../../services/api.service'

export const monetaryModule = {
    namespaced: true,
    state: {},
    getters: {
        canReadMonetary(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('monetary');
        },
        canEditMonetary(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('monetary');
        },

    },
    mutations: {},
    actions: {
        getData() {
            return new Promise(function (resolve) {

                resolve();

            })
        },

        getAllMonetary({dispatch, state, rootGetters}, {page, amountPerPage, filter, sortby, sortcolumn}) {

            return new Promise(function (resolve, reject) {
                dispatch('getData', false).finally(() => {
                    api.sendRequest('monetaryTransaction/get', {
                        amount: (amountPerPage == null ? state.page.amountPerPage : amountPerPage),
                        page: page == null ? 0 : page,
                        buids: rootGetters['data/getSelectedBranchesIds'].join(","),

                        sortby, sortcolumn,
                    }, filter).then(
                        (ans) => {
                            if (ans["monetary"] == null)
                                return reject('Could not get monetary ');
                            resolve({
                                invoices: ans['monetary'],
                                data: ans['monetaryData'],
                                contracts: ans['contracts'],
                                properties: ans['properties'],
                                amountPerPage: ans['Amount'],
                                total: ans['Total'],
                                page: ans['Page']
                            })
                        }, (error) => {

                            reject(error.message)
                        })
                })

            })
        },
        getStats({dispatch, state}, {start, end}) {

            return new Promise(function (resolve, reject) {
                api.sendRequest('monetaryTransaction/getStats', {
                    start: start,
                    end: end
                }, null).then(
                    (ans) => {
                        if (ans["successPayments"] == null)
                            return reject('Could not get statistics ');
                        resolve({
                            payments: ans['successPayments'],
                            orders: ans['completedOrders'],
                            products: ans['productsSold'],
                        })
                    }, (error) => {
                        reject(error.message)
                    })
            })

        },

        getMonetaryById(actions, id) {
            return new Promise((resolve, reject) => {

                api.sendRequest('monetaryTransaction/id/' + id, {}).then(
                    (ans) => {

                        if (ans["monetary"] == null)
                            return reject('Could not get monetary');
                        resolve(ans["monetary"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        payMonetaryTax(actions, {id}) {
            return new Promise((resolve, reject) => {

                api.sendRequest('monetaryTransaction/payTax/' + id, {}).then(
                    (ans) => {

                        actions.dispatch('notifications/success',
                            {
                                title: {text: '%s', arguments: ['Local tax']},
                                message: {text: '%s  successfully', arguments: ['Taxe paid']}
                            },
                            {root: true});
                        resolve(ans["monetary"]);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: {text: '%s', arguments: ['Local tax']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        payMultiMonetaryTax(actions, {ids}) {
            return new Promise((resolve, reject) => {

                api.sendRequest('monetaryTransaction/payTax/multiple', {}, ids).then(
                    (ans) => {
                        resolve(ans["monetary"]);

                        actions.dispatch('notifications/success',
                            {
                                title: {text: '%s', arguments: ['Local tax']},
                                message: {text: '%s  successfully', arguments: ['Taxes paid']}
                            },
                            {root: true});
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: {text: '%s', arguments: ['Local tax']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },

        makeMultiMonetaryVisible(actions) {
            return new Promise((resolve, reject) => {

                api.sendRequest('realEstate/contract/visible/multiple', {}).then(
                    (ans) => {
                        resolve(ans["success"]);

                        actions.dispatch('notifications/success',
                            {
                                title: {text: '%s', arguments: ['Invoices']},
                                message: {text: '%s  successfully', arguments: ['Invoices made visible']}
                            },
                            {root: true});
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: {text: '%s', arguments: ['Invoices']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },

        sendInvoiceToMail(actions, {id,email}) {
            return new Promise((resolve, reject) => {

                api.sendRequest('monetaryTransaction/sendInvoice/' + id, {email}).then(
                    (ans) => {

                        if (ans["monetary"] == null)
                            return reject('Could not send invoice');
                        actions.dispatch('notifications/success',
                            {
                                title: {text: '%s' + id, arguments: ['Invoice #']},
                                message: {text: '%s  successfully', arguments: ['Invoice sent']}
                            },
                            {root: true});
                        resolve(ans["monetary"]);
                    }, (error) => {
                        actions.dispatch('notifications/error',
                            {
                                title: {text: '%s' + id, arguments: ['Invoice #']},
                                message: (typeof error === "object" && error.message !== undefined ? error.message : error)
                            },
                            {root: true});
                        reject(error.message)
                    })
            })
        },
        getPdfInvoiceUrl(actions, id) {
            return new Promise(function (resolve, reject) {
                let params = {
                    bearer: encodeURI(localStorage.Bearer),
                    id: id
                };
                let query = new URLSearchParams(params).toString()
                resolve(window.config.apiUrl + 'monetaryTransaction/downloadInvoice?' + query);
            });
        },
        generateExcelOverview(actions,{platform,buildingId,start,end}) {
            return new Promise(function (resolve, reject) {
                let params = {
                    bearer: encodeURI(localStorage.Bearer),
                    platform: platform,
                    buildingId: buildingId,
                    start: start,
                    end: end
                };
                let query = new URLSearchParams(params).toString()
                resolve(window.config.apiUrl + 'monetaryTransaction/generateOverviewExcel?' + query);
            });
        },
        getPdfInvoicesUrl(actions,ids) {

            return new Promise(function (resolve, reject) {
                let params = {
                    bearer: encodeURI(localStorage.Bearer),
                    ids: ids.join(","),
                };
                let query = new URLSearchParams(params).toString()
                resolve(window.config.apiUrl + 'monetaryTransaction/downloadInvoices?' + query);
            });
        },
        createMonetary({dispatch}, {monetary}) {
            return new Promise(function (resolve, reject) {

                api.sendRequest('monetaryTransaction/add', null, monetary)
                    .then(
                        (ans) => {
                            //In case of success send notification
                            dispatch('notifications/success',
                                {
                                    title: {text: '%s added', arguments: ['order']},
                                    message: {text: '%s added successfully', arguments: ['order']}
                                },
                                {root: true})

                            resolve(ans)
                        }, (error) => {
                            dispatch('notifications/error',
                                {
                                    title: 'Add transaction',
                                    message: (typeof error === 'object' && error.message !== undefined ? error.message : error)
                                },
                                {root: true})
                            reject(error.message)
                        })
            })
        },

        cashAdd({dispatch}, {monetary}) {
            return new Promise(function (resolve, reject) {

                api.sendRequest('monetaryTransaction/cashAdd', null, monetary)
                    .then(
                        (ans) => {
                            //In case of success send notification
                            dispatch('notifications/success',
                                {
                                    title: {text: '%s added', arguments: ['Deposit']},
                                    message: {text: '%s added successfully', arguments: ['Deposit']}
                                },
                                {root: true})

                            resolve(ans)
                        }, (error) => {
                            dispatch('notifications/error',
                                {
                                    title: 'Add transaction',
                                    message: (typeof error === 'object' && error.message !== undefined ? error.message : error)
                                },
                                {root: true})
                            reject(error.message)
                        })
            })
        },
        chargeCustomer({dispatch}, {contractId,amount,reason}) {
            return new Promise(function (resolve, reject) {

                api.sendRequest('monetaryTransaction/stripePayment/'+contractId, {

                    amount:amount,
                    reason:reason
                })
                    .then(
                        (ans) => {
                            //In case of success send notification
                            dispatch('notifications/success',
                                {
                                    title: {text: 'Stripe payment'},
                                    message: {text: 'Customer charged successfully'}
                                },
                                {root: true})

                            resolve(ans)
                        }, (error) => {
                            dispatch('notifications/error',
                                {
                                    title: 'Stripe payment',
                                    message: (typeof error === 'object' && error.message !== undefined ? error.message : error)
                                },
                                {root: true})
                            reject(error.message)
                        })
            })
        },
        chargeOwner({dispatch}, {ownerId,buildingId,amount,reason}) {
            return new Promise(function (resolve, reject) {

                api.sendRequest('monetaryTransaction/stripePayment/owner', {

                    ownerId:ownerId,
                    buildingId:buildingId,
                    amount:amount,
                    reason:reason
                })
                    .then(
                        (ans) => {
                            //In case of success send notification
                            dispatch('notifications/success',
                                {
                                    title: {text: 'Stripe payment'},
                                    message: {text: 'Customer charged successfully'}
                                },
                                {root: true})

                            resolve(ans)
                        }, (error) => {
                            dispatch('notifications/error',
                                {
                                    title: 'Stripe payment',
                                    message: (typeof error === 'object' && error.message !== undefined ? error.message : error)
                                },
                                {root: true})
                            reject(error.message)
                        })
            })
        },
        cashRemove({dispatch}, {monetary}) {
            return new Promise(function (resolve, reject) {

                api.sendRequest('monetaryTransaction/cashRemove', null, monetary)
                    .then(
                        (ans) => {
                            //In case of success send notification
                            dispatch('notifications/success',
                                {
                                    title: {text: '%s added', arguments: ['Withdraw']},
                                    message: {text: '%s added successfully', arguments: ['Withdraw']}
                                },
                                {root: true})

                            resolve(ans)
                        }, (error) => {
                            dispatch('notifications/error',
                                {
                                    title: 'Add transaction',
                                    message: (typeof error === 'object' && error.message !== undefined ? error.message : error)
                                },
                                {root: true})
                            reject(error.message)
                        })
            })
        },

    },
};
